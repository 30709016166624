body {
  font-family: 'Roboto Regular', sans-serif;
  padding: 0;
  margin: 0 auto;
  color: black;
}

h1 {
  font-family: 'Clancy Regular';
  font-weight: normal;
  font-size: 55px;
  line-height: 66px;
  margin-top: 61px;
}

h2 {
  font-family: 'Clancy Regular';
  font-weight: normal;
  font-size: 30px;
  line-height: 36px;
  margin-top: 60px;
  margin-bottom: 30px;
}

h3 {
  font-family: 'Clancy Regular';
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
}

h4 {
  font-family: 'Clancy Regular';
  font-weight: normal;
  font-size: 20px;
}

p {
  font-family: 'Roboto Light';
  font-size: 16px;
  line-height: 26px;
}

p strong {
  font-family: 'Roboto Regular';
}

a:hover {
  text-decoration: none;
}

ul {
  list-style: none;
  padding-left: 0;
}

li {
  font-family: 'Roboto Light';
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 12px;
  list-style-type: none;
  background-image: url('../assets/list-icon.svg');
  background-repeat: no-repeat;
  background-position-y: 3px;
  padding-left: 40px;
}

.videoPlayerBox {
  border: 4px solid black;
}

.videoPlayerBox div {
  height: 299px;
}

.stepQuote {
  transition: all 500ms;
  transition-delay: 200ms;
}

.infoBox {
  border: 4px solid;
  transition: all 500ms;
  max-height: 0;
  opacity: 0;
  box-sizing: content-box;
  overflow: hidden;
}

.infoBox h4 {
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.infoBox p {
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
}

.inputField,
.question-label {
  transition: all 500ms;
  transition-delay: 300ms;
}

.question-container {
  margin-bottom: 25px;
}

.navButtons {
  margin-top: 50px;
  position: relative;
  transition: all 500ms;
  height: 58px;
}

button {
  width: 157px;
  height: 58px;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  font-family: 'Roboto Mono Bold';
  font-size: 18px;
  text-align: right;
  padding-right: 20px;
  position: relative;
  transition: all 200ms;
}

textarea {
  width: 100%;
  background-color: #f2f2f2;
  border: none;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Roboto Mono Regular';
  font-size: 16px;
  margin-top: 15px;
  border-radius: 0px;
  appearance: none;
}

.maxLengthMessage {
  text-align: right;
}

.overMaxLength {
  color: #fb6561;
}

input {
  width: 100%;
  background-color: #f2f2f2;
  border: none;
  height: 58px;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Roboto Mono Regular';
  font-size: 16px;
  margin-top: 5px;
  border-radius: 0px;
  appearance: none;
}

input:focus,
button:focus,
textarea:focus {
  border: 1px solid black;
  outline: none;
}

.input-error {
  border: 1px solid #fb6561 !important;
}

.input-error::placeholder {
  color: #fb6561 !important;
  opacity: 1;
}

.input-error:-ms-input-placeholder {
  color: #fb6561;
}

.buttongroup-container button {
  padding: 7px 10px;
  border-radius: 12px;
  margin-right: 10px;
  margin-top: 10px;
  font-family: 'Roboto Light';
  font-size: 16px;
  cursor: pointer;
  transition: all 200ms;
  color: black;
  border: 1px solid black;
  height: auto;
  width: auto;
  text-align: left;
}

.buttongroup-container button.active {
  color: white;
}

label {
  font-family: 'Roboto Light';
  font-size: 16px;
  line-height: 26px;
  margin-top: 15px;
  display: block;
}

.infoLink {
  padding: 0px 10px;
  cursor: pointer;
  display: inline-block;
}

.scrollBlock {
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-left: 165px;
  display: flex;
  -ms-overflow-style: none;
}

.scrollBlock::-webkit-scrollbar {
  display: none;
}

.scrollContents {
  display: inline-flex;
}

.groupInputError {
  background-color: #fb656122;
  padding-left: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.errorMessage {
  margin: 0px;
  padding: 0px;
  color: #fb6561;
  font-size: 14px;
  font-family: 'Roboto Regular';
}

.workbookButton {
  height: 40px;
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  text-decoration: none;
}

.wbArrow {
  margin-top: 2px;
}

.wbLabel {
  padding-left: 20px;
  font-family: 'Roboto Mono Bold';
  font-size: 14px;
  line-height: 14px;
}

@media screen and (max-width: 900px) {
  h1 {
    margin-top: 22px;
  }

  h4 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .scrollBlock {
    padding-left: 15px;
  }

  .videoPlayer {
    width: 100%;
    height: 200px;
  }

  .videoPlayerBox div {
    height: 200px;
  }
}

@font-face {
  font-family: 'Clancy Regular';
  src: url(../assets/fonts/Clancy-Regular.woff2) format('woff2'),
    url(../assets/fonts/Clancy-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto Light'),
    url(../assets/fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Regular';
  src: local('Roboto Regular'),
    url(../assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Mono Light';
  src: local('Roboto Mono Light'),
    url(../assets/fonts/RobotoMono-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Mono Regular';
  src: local('Roboto Mono Regular'),
    url(../assets/fonts/RobotoMono-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto Mono Bold';
  src: local('Roboto Mono Bold'),
    url(../assets/fonts/RobotoMono-Bold.ttf) format('truetype');
}
